import React, { useEffect, useState } from 'react';
import './WBSPage.css';
const MLDLucky = () => {
    const [userData, setUserData] = useState([]);
    const [showNotificationForm, setShowNotificationForm] = useState(false);
    const [recipientId, setRecipientId] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        fetchUserData();
    }, []);
    const fetchUserData = async () => {
        try {
            const response = await fetch(
                // `https://dev.myluckydays.app/api/V2.0/Notification//get_user_by_luckyday?pageNumber=${currentPage - 1}&pageSize=10&sortMode=Asc`,
                `https://test.myluckydays.ai/api/V2.1/User_Authentication/get_all_users?page_number=${currentPage - 1}&page_size=5`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Environment': 'MLD DEV'
                    }
                }
            );
            const data = await response.json();
            setUserData(data);
            console.log('User Data:', data); // Log the received data in the console
        } catch (error) {
            console.log('Error fetching user data:', error);
        }
    };


    const maskFcmToken = (token) => {
        if (token && token.length > 8) {
            const visibleLength = 4;
            const maskedChars = token.length - (visibleLength * 2);
            const maskedPart = '*'.repeat(maskedChars);
            return token.substr(0, visibleLength) + maskedPart + token.substr(-visibleLength);
        }
        return token;
    };
    const handleNextPage = () => {
        if (userData?.data && userData.data.length === 10) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    // const handleSendNotification = (userId) => {
    //     // Logic to send notification for the given user
    //     console.log('Send notification for user with ID:', userId);
    // };
    const handleSendNotification = (userId) => {
        // Logic to send notification for the given user
        console.log('Send notification for user with ID:', userId);
        // Show the notification form
        setShowNotificationForm(true);
        // Set the recipient ID
        setRecipientId(userId);
    };

    // const handleSubmitNotificationForm = (event) => {
    //     event.preventDefault();
    //     console.log('Form submitted');
    //     setShowNotificationForm(false);
    // };
    const handleSubmitNotificationForm = async (event) => {
        event.preventDefault();
        console.log('Form submitted');

        // Prepare the form data from the state
        const formData = {
            notification_name: event.target.notification_name.value,
            recipient_id: recipientId,
            notification_type_id: "3",
            notification_text: event.target.notification_text.value,
            target_screen: event.target.target_screen.value, // Add target_screen to formData
            is_active: "1",
        };

        try {
            const response = await fetch(
                // 'https://dev.myluckydays.app/api/V2.0/Notification/post_birthday_wish_log',
                `https://test.myluckydays.ai/api/V2.1/User_Authentication/send_notifications`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Environment': 'MLD DEV',
                    },
                    body: JSON.stringify(formData),
                }
            );

            // Get the raw response text without parsing as JSON
            const responseData = await response.text();

            if (response.ok) {
                console.log('Form submitted successfully.');
                // console.log('API Response:', responseData); // Log the raw response data
            } else {
                console.log('Form submission failed.');
                console.log('API Response Error:', responseData); // Log the raw response data on failure as well
            }
        } catch (error) {
            console.log('Error submitting form:', error);
        }

        // Hide the notification form after form submission
        setShowNotificationForm(false);
    };





    return (
        <div>
            <h1>MLD Page</h1>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>FCM Token</th>
                        <th>Notification</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(userData?.data) && userData.data.length > 0 ? (
                        userData.data.map((user) => (
                            <tr key={user.user_id}>
                                <td>{user.your_name}</td>
                                <td>{user.email}</td>
                                <td className="fcmtoken-cell">{maskFcmToken(user.fcm_token)}</td> {/* Masked fcm_token */}
                                <td>
                                    <button
                                        className="send-button"
                                        onClick={() => handleSendNotification(user.user_id)}
                                    >
                                        Send
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No user data available.</td>
                        </tr>
                    )}
                </tbody>

            </table>
            {/* Form Section */}
            {showNotificationForm && (
                <div className="notification-form-container">
                    <h2>Notification Form</h2>
                    <form onSubmit={handleSubmitNotificationForm}>
                        <label>
                            Notification Name:
                            <input type="text" name="notification_name" required />
                        </label>
                        <label>
                            Recipient ID:
                            <input type="text" name="recipient_id" value={recipientId} readOnly />
                        </label>
                        {/* <label>
                            Notification Type ID:
                            <input type="text" name="notification_type_id" required />
                        </label> */}
                        <label>
                            Notification Text:
                            <textarea name="notification_text" required />
                        </label>
                        {/* <label>
                            Is Active:
                            <input type="checkbox" name="is_active" />
                        </label> */}
                        {/* <label>
                            Target Screen:
                            <select name="target_screen" required>
                                <option value="">None</option>
                                <option value="chatScreen">Chat Screen</option>
                                <option value="homeScreen">Home Screen</option>
                                <option value="CoinsScreen">Coins Screen</option>
                                <option value="horoscopeScreen">Horoscope Screen</option>
                                <option value="luckyDaysScreen">Lucky Days Screen</option>
                                <option value="kundaliScreen">Kundali Screen</option>
                                <option value="weeklyPredictionScreen">Weekly Prediction Screen</option>
                            </select>
                        </label> */}
                        <label>
                            Target Screen:
                            <select name="target_screen" defaultValue="">
                                <option value="">None</option>
                                <option value="chatScreen">Chat Screen</option>
                                <option value="homeScreen">Home Screen</option>
                                <option value="CoinsScreen">Coins Screen</option>
                                <option value="horoscopeScreen">Horoscope Screen</option>
                                <option value="luckyDaysScreen">Lucky Days Screen</option>
                                <option value="kundaliScreen">Kundali Screen</option>
                                <option value="weeklyPredictionScreen">Weekly Prediction Screen</option>
                            </select>
                        </label>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            )}
            <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage}</span>
                <button onClick={handleNextPage} disabled={!userData?.data || userData.data.length < 10}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default MLDLucky;
