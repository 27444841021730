/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Header of Home Screen
Brief :Header Component: This component represents the header section of the website, handling navigation and display functionalities.
Navigation: It contains navigation elements like the website logo, tabs for different sections (e.g., Home, Audio Stories), a 'Write' button, and a mobile menu for responsive design.
Search Functionality: It includes a search bar that allows users to input text and search for stories, audios, and authors. Pressing 'Enter' triggers a search with the entered text.
State Management: It manages states for menu visibility, search input visibility, search text, and active tabs.
Routing: Utilizes the react-router-dom library for navigation using the useNavigate hook and tracks the current location with the useLocation hook.
Event Handling: Handles click events for navigation tabs, the website logo, and the 'Write' button. It also manages the mobile menu toggle and search icon toggle events.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react'
import './Header.css'
import Logo from '../../Asset/Images/Navbar/Logo.webp'
import Profile from './Assets/profile.webp'
import { useNavigate, useLocation } from 'react-router-dom';
import './Slider.css'
import HamburgerMenu from './HamburgerMenu';
// import NewSearch from '../StoriesList/NewSearch';
// import Navigation from './Navigation';

function Header(props) {

    const navigate = useNavigate();
    const location = useLocation();


    const getInitials = (name) => {
        if (!name) return '';
        const nameArray = name.split(' ');
        const initials = nameArray.map(n => n[0]).join('');
        return initials.toUpperCase();
    };
    const [activeTab, setActiveTab] = useState('home'); // Initial active tab


    const yellowColor = {
        color: ' #403085',
    };

    useEffect(() => {
        // Reset the active tab when the route changes
        const pathname = location.pathname;
        switch (pathname) {
            case '/kids-bedtime-stories-audio':
                setActiveTab('kids-bedtime-stories-audio');
                break;
            case '/': // Add other route cases if needed
                setActiveTab('home');
                break;
            case '/authorlist': // Add other route cases if needed
                setActiveTab('authorlist');
                break;
            case '/videostories':
                setActiveTab('videostories');
                break;
            // Add additional cases for other routes if needed
            default:
                setActiveTab(''); // Reset to default active tab when route doesn't match
                break;
        }
    }, [location.pathname]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        navigate(`/${tabName === 'home' ? '' : tabName}`); // Navigate based on tab clicked
    };


    const handleLoginSignUp = () => {
        navigate('/notify');
    }
    const handleUserProfile = () => {
        navigate('/userprofile'
        );
    }




    return (
        <div className='ws-new-header'>
            <div className='ws-header'>
                <div className='header'>
                    <div className='ws-header-logo' onClick={() => {
                        navigate('/'
                        )
                    }}
                    >
                        <img src={Logo} alt='App-logo'></img>
                    </div>
                </div>
                <div className='header2'>
                    <div
                        className={`home ${activeTab === 'home' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('home'); navigate('/'
                            );
                        }}
                    >Home</div>
                    <div
                        className={`audio ${activeTab === 'kids-bedtime-stories-audio' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('kids-bedtime-stories-audio'); navigate('/products'
                            );
                        }}
                    >Products</div>
                    <div
                        className={`audio ${activeTab === 'videostories' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('videostories'); navigate('/about'
                            );
                        }}
                    >About Us</div>
                    <div
                        className={`author ${activeTab === 'authorlist' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('authorlist'); navigate('/contact'
                            );
                        }}
                    >Contact Us</div>
                </div>
                {/* <NewSearch totalStories={totalStories}
                /> */}
                <div className='header3'>
                    <div onClick={handleLoginSignUp} className='divlog'>
                        <img src={Profile} alt='profile' />
                    </div>
                    {/* <div className='divwrite'>
                        <button className='writebtn'>Write</button>
                    </div>
                    <div className='divwrite'></div>

                    <div className='divwrite'>
                        <button className='logoutbtn'
                        >Logout
                        </button>
                    </div> */}
                </div>
            </div>
            <div className='mobile'>
                <HamburgerMenu />
            </div>
            <div className='web'>
                <div className='new'>
                </div>
            </div>
        </div>
    )
}

export default Header
