import React from "react";
import { NavLink } from "react-router-dom";
import "../Home/Home.css"
import home_image from "../../Asset/Images/home/10798281_19362653 1.png"
import Navbar from "../Navbar/Navbar";
import '../Home/Homes.css';
function Home() {
    return (
        <>
            <Navbar />
            <div className="image-text-container">
                <div className="image-overlay">
                    <img src={home_image} alt="By johnniedavid12412" />
                </div>
                <div className="text-container">
                    <div className="kt-discover">
                        We believe in delivering the <span className="break">best User Experience</span>
                        {/* <div className='ws-search-distinct'>{storyCountData && (<strong>{storyCountData.totalAuthors}</strong>)}&nbsp;Authors, {storyCountData && (<strong>{storyCountData.totalStories} </strong>)} stories, enchanting short bedtime stories every night</div> */}
                    </div>
                </div>
            </div>

        </>
    );
}
export default Home;