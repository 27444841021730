/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Humberger Menu Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import logo from '../../Asset/Images/Navbar/Logo.webp'
import SearchMob from './Assets/Search2.webp';
import PlayStore from './Assets/GooglePlay.webp'
import Instagram from './Assets/Instagram.webp'
import Facebook from './Assets/Facebook.webp'
import Twitter from './Assets/Twitter.webp'
import YouTube from './Assets/youtube.webp'
import Cross from './Assets/Cross.webp'
import Hamburger from './Assets/Menu Hamburger.webp'
import './Hamburger.css'; // Ensure this is included
import { useLocation, useNavigate } from 'react-router-dom';

const HamburgerMenu = (props) => {
    const location = useLocation();
    const [suggestions, setSuggestions] = useState([]);
    const [showNoSuggestions, setShowNoSuggestions] = useState(false);
    const suggestionBoxRef = useRef(null);


    const navigate = useNavigate();
    const totalStories = props.totalStories;
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState('home'); // Initial active tab


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const yellowColor = {
        color: ' #403085',
    };
    const [searchInputVisible, setSearchInputVisible] = useState(false);
    const handleSearchIconClick = () => {
        setSearchInputVisible(!searchInputVisible); // Toggle input visibility
    };
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);

        // Show suggestions only if there's input and suggestions available
        if (inputValue.trim() !== '' && suggestions.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };




    const [showSuggestions, setShowSuggestions] = useState(false); // Add state to manage suggestion box visibility

    // Function to handle click on the input field
    const handleInputClick = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true); // Show suggestions when input field is clicked and suggestions exist
        }
    };

    useEffect(() => {
        // Reset the active tab when the route changes
        const pathname = location.pathname;
        switch (pathname) {
            case '/products':
                setActiveTab('products');
                break;
            case '/': // Add other route cases if needed
                setActiveTab('home');
                break;
            case '/about': // Add other route cases if needed
                setActiveTab('about');
                break;
            case '/contact': // Add other route cases if needed
                setActiveTab('contact');
                break;
            // Add additional cases for other routes if needed
            default:
                setActiveTab(''); // Reset to default active tab when route doesn't match
                break;
        }
    }, [location.pathname]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        navigate(`/${tabName === 'home' ? '' : tabName}`); // Navigate based on tab clicked
    };
    const handleLoginSignUp = () => {
        navigate('/loginpop');
    }


    return (
        <div>
            <header className="header">
                {/* Hamburger Menu on the right */}
                <div className="menu-icon" onClick={toggleMenu}>
                    {isOpen ?
                        // '☰'
                        <>
                            <img src={Hamburger} alt='Hamburger' />
                        </>
                        :
                        <img src={Hamburger} alt='Hamburger' />
                    } {/* Hamburger icon only when menu is not open */}
                </div>


                {/* Title in the middle */}
                <h1 className="title"><span style={yellowColor}>Kushmanda</span>&nbsp;Tech</h1>

                {/* Logo on the left */}
                <div className="logo-container" onClick={() => { navigate('/'); window.scrollTo(0, 0); }}>
                    <img src={logo} alt="logo" className="logo" />
                </div>


                {/* Navigation Menu */}
                <div className={`overlay ${isOpen ? 'show' : ''}`} onClick={toggleMenu}></div>
                <nav className={`menu ${isOpen ? 'show' : ''}`}>
                    {/* Close Icon */}
                    <div className="close-icon" onClick={toggleMenu}>
                        {isOpen ?
                            <>
                                <img src={Cross} alt='cross' />
                            </>
                            :
                            ''
                        } {/* X icon only when menu is open */}
                    </div>
                    {/* '✖' */}
                    <div className='menu-data'>
                        <ul>


                            <li><div onClick={() => { navigate('/') }} >Home
                            </div></li>
                            <li><div onClick={() => {
                                handleTabClick('products'); navigate('/products'
                                    // , { state: { authToken: authToken, from: '' } }
                                );
                            }}
                            >Products</div></li>
                            <li>
                                <div onClick={() => {
                                    handleTabClick('about'); navigate('/about'
                                        // , { state: { authToken: authToken, from: '' } }
                                    );
                                }}
                                >About Us
                                </div>
                            </li>
                            <li><div onClick={() => {
                                handleTabClick('contact'); navigate('/contact'
                                    // , { state: { authToken: authToken, from: '' } }
                                );
                            }}>Contact Us</div></li>
                            <li>

                                <div onClick={() => {
                                    navigate('/userprofile'
                                        // , { state: { authToken: authToken, from: '' } }
                                    );
                                }}>
                                    My Profile
                                </div>

                            </li>
                        </ul>
                        {/* <div className='header-am'>
                            <div onClick={() => { navigate('/aboutus'); window.scrollTo(0, 0); }}>Our Story</div>
                            <div onClick={() => { navigate('/cookie'); window.scrollTo(0, 0); }}>Cookie Policy</div>
                            <div onClick={() => { navigate('/privacy'); window.scrollTo(0, 0); }}>Privacy Policy</div>
                            <div onClick={() => { navigate('/term'); window.scrollTo(0, 0); }}>Terms of Use</div>
                            <div >Logout</div>
                        </div>
                        {isOpen && (
                            <div className='bottom-nav'>
                                <div className='header-app-store'>
                                    <div>Use the App</div>
                                    <div onClick={() => { window.open('//play.google.com/store/apps/details?id=tech.kushmanda.wikibedtimestories&hl=en&gl=US', '_blank') }}> <img src={PlayStore} alt='PlayStore' /></div>
                                </div>
                                <div className='header-follow-us'>
                                    Follow Us
                                </div>
                                <div className='social-media'>

                                    <div className='social-logo' onClick={() => { window.open('//www.facebook.com/profile.php?id=100076326081861', '_blank') }}>
                                        <img src={Facebook} alt='facebook' />
                                    </div>
                                    <div className='social-logo'>
                                        <img src={Twitter} alt='twitter' onClick={() => { window.open('//twitter.com/wikibedtime', '_blank') }} />
                                    </div>
                                    <div className='social-logo' onClick={() => { window.open('//www.instagram.com/wikibedtimestories/', '_blank') }}>
                                        <img src={Instagram} alt='instagram' />
                                    </div>
                                    <div className='social-logo'>
                                        <img src={YouTube} alt='youtube' onClick={() => { window.open('//youtube.com/@Wikibedtimestories', '_blank') }} />
                                    </div>
                                </div>
                            </div>
                        )} */}
                        {/* <div className='header-rights'>© All rights reserved.</div> */}
                    </div>

                </nav>
            </header>
            
        </div>

    );
};

export default HamburgerMenu;
