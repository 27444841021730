import React from 'react';
import { Link } from 'react-router-dom';
// import './LandingPage.css';

const WBSLanding = () => {
    return (
        <div className="landing-page">
            <h1>Welcome to the WBS Notification Page</h1>
            <div className="button-container">
                <Link to="/wbsbirthday" className="landing-button">User Personalise Notification</Link>

            </div>
            {/* <div className="button-container">
                <button className="landing-button">WBS</button>
                <button className="landing-button">MLD</button>
                <button className="landing-button">Aries</button>
            </div> */}
        </div>
    );
}

export default WBSLanding;